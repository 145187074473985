import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Tabs,
  Tab,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTE_PATH } from '../../../routes/Routes';
import { courses } from '../../../mock/Cousedata';
import { useTheme } from '@emotion/react';

const CoursesCard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedCategory, setSelectedCategory] = useState('Trending courses');

  const categories = ['Trending courses', ...new Set(courses.map(course => course.category))]; // Extract unique categories

  const handleCategoryChange = (event, newCategory) => {
    setSelectedCategory(newCategory);
  };

  const onCourseSelect = (courseId) => {
    navigate(`${CONST_ROUTE_PATH.USER_DETAILS}/${courseId}`);
  };

  // Filter courses based on selected category
  const filteredCourses = selectedCategory === 'Trending courses'
    ? courses
    : courses.filter(course => course.category === selectedCategory);

  return (
    <Box
      sx={{
        minHeight: '60vh',
        backgroundColor: '#FFF5EE',
        display: { md: 'flex' },
        position: 'relative',
        p: { sm:'0.5rem', lg: '3rem 9rem' }
      }}
    >
      {/* Main Content Area */}
      <Box sx={{ padding: 3, flexGrow: 1 }}>
        <Typography fontWeight={550} mb={2}  sx={{fontSize:{xs:"2rem" , md:"3rem"}}} fontFamily="sans-serif" textAlign="center" color="#FF6F2F" variant="h3" gutterBottom>
          Our In-Demand Courses
        </Typography>
        <Typography fontWeight={550} mb={3} textAlign="center" fontFamily="serif" color="gray" variant="h6" gutterBottom>
          Master Skills for the Future with Our Expert-Led Courses
        </Typography>

        {/* Tabs for Categories */}
        <Tabs
          value={selectedCategory}
          onChange={handleCategoryChange}
          textColor="primary"
          variant={isSmallScreen ? "scrollable":"standard"}
          centered

          indicatorColor="primary"
          sx={{ marginBottom: 5 , '& .MuiTabs-indicator': {
            display: 'none', // Remove the active tab indicator
          }
          }}
         
        >
          {categories.map((category) => (
            <Tab sx={{

              borderBottom: "none", // Remove bottom border for the tab
              textTransform: 'none', // Prevent capitalizing the text
              fontWeight: 550,
              fontSize:"1.1rem",
              '&.Mui-selected': {
  
                bgcolor: selectedCategory ? '#FF6F2F' : 'transparent', // Active tab background color
                color: selectedCategory? 'white' : '#000', // Active tab text color
                transition:"all 0.3s ease"
  
              },
              // '&:hover': {
              //   color: "#fff", // Hover text color
              //   bgcolor: '#555', // Hover background color
              // },
              borderRadius: 1,
            }} key={category} label={category} value={category} />
          ))}
        </Tabs>

        {/* Render Filtered Courses */}
        <Grid container spacing={5} justifyContent="center">
          {filteredCourses.map((course) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={course.id}>
              {/* Image that covers the entire card */}
              <Box
                sx={{
                  backgroundImage: `url(${course.thumbnail})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100% 100%",
                  borderRadius: '1vw',
                  position: 'relative',
                  overflow: 'hidden',
                  minHeight: '30vh',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                {/* Overlay content (description and button) */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'rgba(0, 0, 0, 0.9)', // Dark overlay background
                    color: 'white',
                    opacity: 0,
                    transition: 'opacity 0.3s ease',
                    '&:hover': {
                      opacity: 1, // Show content on hover
                    },
                  }}
                >
                  <Typography ml={2} mr={2} fontWeight={550} fontFamily="sans-serif" variant="body2" align="center" gutterBottom>
                    {course.description}
                  </Typography>
                  <Button
                    variant="contained"
                    
                    sx={{ backgroundColor: '#FF6F2F', color: '#000000', mt: 2,fontStyle:"italic",fontWeight:550 }}
                    onClick={() => onCourseSelect(course.id)}
                  >
                    Enroll
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  width: '100%',
                  color: '#0F2A47',
                  padding: '10px',
                  textAlign: 'center',
                }}
              >
                <Typography variant="h5" fontFamily="sans-serif" fontStyle="italic" component="div">
                  {course.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CoursesCard;
