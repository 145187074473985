import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Box, Typography } from '@mui/material';

const VideoPlayer = ({ videoUrl, thumbnailUrl, breakpoints }) => {
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false); // Initially not playing
  const [quiz, setQuiz] = useState(null); // Holds current quiz to display
  const [playedSeconds, setPlayedSeconds] = useState(0); // Track video play time

  // Check for quiz breakpoints and pause video
  useEffect(() => {
    const breakpoint = breakpoints?.find(bp => Math.floor(playedSeconds) === bp.time);
    if (breakpoint) {
      setPlaying(false); // Pause video when breakpoint is hit
      setQuiz(breakpoint.quiz); // Show quiz
    }
  }, [playedSeconds, breakpoints]);

  // Handle quiz submission
  const handleQuizSubmit = (selectedOption) => {
    if (selectedOption === quiz.correct) {
      setQuiz(null); // Hide quiz
      setPlaying(true); // Resume video playback
    } else {
      alert('Incorrect, try again.'); // Retry on wrong answer
    }
  };

  useEffect(() => {
    if (videoUrl) {
      setPlaying(true); // Play automatically when a video is selected
    } else {
      setPlaying(false); // Stop playing if no video is selected
    }
  }, [videoUrl]);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: 'auto' }}>
      {!videoUrl ? (
        <Box
          sx={{
            width: '100%',
            height: '60vh',
            backgroundImage: `url(${thumbnailUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5" color="white">
            Select a video to start
          </Typography>
        </Box>
      ) : (
        <ReactPlayer
          url={videoUrl}
          ref={playerRef}
          playing={playing}
          controls
          onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
          width="100%"
          height="60vh"
        />
      )}

      {quiz && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: '#fff',
            padding: '20px',
            zIndex: 10,
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0,0,0,0.2)',
          }}
        >
          <Typography variant="h6">{quiz.question}</Typography>
          {quiz.options.map((option) => (
            <Typography
              key={option}
              sx={{ cursor: 'pointer', marginTop: '10px' }}
              onClick={() => handleQuizSubmit(option)}
            >
              {option}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default VideoPlayer;
