import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, Button, CircularProgress, LinearProgress } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import Navbar from '../../components/common/Navbar';
import { Footer } from '../../components/common/Footer';
import CoursesApiService from '../../services/CoursesApiServices';
import { useNavigate } from 'react-router-dom';
import { CONST_ROUTE_PATH } from '../../routes/Routes';

export const MyCourses = () => {
    const { userId } = useAuth();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await CoursesApiService.getCoursesbyuserId(userId);
                setCourses(response.data.enrolledCourses); // Assuming API returns an array of enrolled courses
            } catch (error) {
                console.error('Error fetching courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourses();
    }, [userId]);

    const onCourseLearn = (courseId) => {
        navigate(`/course/${courseId}/videos`);
    };

    const completedCourses = courses.filter(course => course.completed);

    return (
        <>
            <Navbar />
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', height: "60vh", alignItems: "center", backgroundColor: '#FFF5EE' }}>
                    <CircularProgress size={50} color="primary" />
                </Box>
            ) : (
                <Box sx={{ backgroundColor: '#FFF5EE' }}>
                    <Box sx={{ p: { xs: '0.5rem', lg: '2rem 9rem' } }}>
                        {/* Header section with background image */}
                        
                        <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', color: '#FF6F2F' }}>
                            My Enrolled Courses
                        </Typography>
                        <Typography variant="h6" align="center" sx={{ marginTop: '20px', color: 'gray' }}>
                            Keep track of your learning progress! Below are all the courses you've enrolled in.
                        </Typography>
                        {/* Enrolled courses grid */}
                        <Grid container spacing={4} sx={{ marginTop: '10px' }}>
                            {courses.length > 0 ? (
                                courses.map((course) => (
                                    <Grid item xs={12} justifyContent="center" sm={6} md={4} key={course.courseId}>
                                        <Card sx={{ maxWidth: 600, borderRadius: '8px', boxShadow: 3 }}>
                                            <Box
                                                sx={{
                                                    backgroundImage: `url(${course.thumbnail})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    height: '300px',
                                                    borderTopLeftRadius: '8px',
                                                    borderTopRightRadius: '8px',
                                                }}
                                            ></Box>

                                            <CardContent>
                                                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                                                    {course.title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ marginTop: '10px' }}>
                                                    {course.description.length > 100
                                                        ? course.description.substring(0, 100) + '...'
                                                        : course.description}
                                                </Typography>

                                                {/* Progress bar */}
                                                <Box sx={{ marginTop: '15px' }}>
                                                    {/* <Typography variant="body2" color="text.secondary">
                                                        {course.completedModules}/{course.totalModules} Modules Completed
                                                    </Typography> */}
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={10}
                                                        sx={{ height: 8, borderRadius: '4px', marginTop: '5px' }}
                                                    />
                                                </Box>

                                                <Typography
                                                    variant="body2"
                                                    sx={{ marginTop: '10px', color: course.completed ? 'green' : 'orange', fontWeight: 'bold' }}
                                                >
                                                    {course.completed ? 'Course Completed' : 'In Progress'}
                                                </Typography>
                                            </CardContent>

                                            {/* Continue/Review button */}
                                            <Box sx={{ padding: '10px' }}>
                                                <Button
                                                    variant="contained"
                                                    sx={{ backgroundColor: '#FF6F2F', color: '#fff', width: '100%' }}
                                                    onClick={() => onCourseLearn(course.courseId)}
                                                >
                                                    {course.completed ? 'Review Course' : 'Continue Learning'}
                                                </Button>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))
                            ) : (
                                <Typography variant="h6" align="center" sx={{ marginTop: '30px' }}>
                                    You are not enrolled in any courses yet. Explore our courses to start learning.
                                </Typography>
                            )}
                        </Grid>

                        {/* Achievements section */}
                        <Box sx={{ marginTop: '50px', textAlign: 'center' }}>
                            <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', color: '#333' }}>
                                Your Achievements
                            </Typography>

                            {completedCourses.length > 0 ? (
                                <>
                                    <Typography variant="h6" align="center" sx={{ marginTop: '20px', color: '#555' }}>
                                        Celebrate your learning milestones! Here are the badges and certificates you've earned.
                                    </Typography>
                                    <Box sx={{ marginTop: '30px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                        <Box sx={{ width: '100px', height: '100px', backgroundColor: '#FF6F2F', borderRadius: '50%', margin: '10px' }}>
                                            <Typography variant="body1" sx={{ color: '#fff', lineHeight: '100px', textAlign: 'center' }}>
                                                Badge 1
                                            </Typography>
                                        </Box>
                                        <Box sx={{ width: '100px', height: '100px', backgroundColor: '#FF6F2F', borderRadius: '50%', margin: '10px' }}>
                                            <Typography variant="body1" sx={{ color: '#fff', lineHeight: '100px', textAlign: 'center' }}>
                                                Badge 2
                                            </Typography>
                                        </Box>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Typography variant="h6" align="center" sx={{ marginTop: '20px', color: '#555' }}>
                                        It seems like you haven't completed any courses yet. Keep learning to earn badges and certificates!
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        sx={{ backgroundColor: '#FF6F2F', color: '#fff', marginTop: '20px' }}
                                        onClick={() => navigate(CONST_ROUTE_PATH.USER_DASHBOARD)}
                                    >
                                        Explore Courses
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
            <Footer />
        </>
    );
};
